@import 'src/utils/utils';

.input {
  @include Text-16-reg;
  width: 100%;
  height: 48px;
  margin: 0;
  padding-top: 10px;
  padding-right: 19px;
  padding-bottom: 11px;
  padding-left: 19px;
  border: 1px solid $Main-5;
  border-radius: 10px;
  color: $Main-1;
  background-color: $Main-2;
  transition:
    color 0.3s,
    box-shadow 0.3s,
    border-color 0.3s;
  outline: none;
  resize: none;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(min-width: $lg) {
    height: 55px;
    padding-top: 14px;
    padding-right: 19px;
    padding-bottom: 14px;
    padding-left: 19px;
  }

  &:disabled {
    color: $Main-6;
    background-color: $Main-3;
    border-color: $Main-3;
    outline: none;
  }

  &::placeholder {
    @include Text-16-reg;
    color: $Main-6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(:disabled):not([readonly]):hover {
    @media(min-width: $lg) {
      box-shadow: 0px 0px 0px 3px rgba(100, 106, 137, 0.25);
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    /* stylelint-disable */
    -webkit-box-shadow: 0 0 0 50px $Main-2 inset !important;
    -webkit-text-fill-color: $Main-1;
    /* stylelint-enable */
  }

  &:not(:disabled):not([readonly]):focus {
    box-shadow: 0px 0px 0px 3px rgba(100, 106, 137, 0.25);
  }

  &[readonly] {
    color: $Main-6;
    background-color: $Main-3;
    border-color: $Main-3;
    outline: none;
  }
}